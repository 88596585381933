import { render, staticRenderFns } from "./GFormSlot.vue?vue&type=template&id=7bcd238d&scoped=true&"
import script from "./GFormSlot.vue?vue&type=script&lang=ts&"
export * from "./GFormSlot.vue?vue&type=script&lang=ts&"
import style0 from "./GFormSlot.vue?vue&type=style&index=0&id=7bcd238d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bcd238d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VDivider,VForm,VRow})
