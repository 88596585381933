
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import InspectionSummaryView from '@/components/dataTables/inspection/InspectionSummaryView.vue'
  import { Inspection } from '@/entities/purchase'
  import { Auto } from '@/entities/public'
  import { capitalize, isValidNumber } from '@/utils/general'
  import FormTitle from '@/components/forms/FormTitle.vue'

@Component({
  components: { FormTitle, InspectionSummaryView },
  methods: {
    capitalize,
  },
})
  export default class InspectionSummary extends Vue {
  @Prop({ type: Object, default: () => null }) inspection!: Inspection | null
  @Prop({ type: Object, default: () => null }) auto!: Auto | null
  @Prop({ type: Boolean }) value: boolean | undefined

  closeInspection () {
    this.$emit('input', false)
  }

  get iconInspection () {
    const { inspection } = this

    return inspection.closingReason?.icon
  }

  get colorInspection () {
    const { inspection } = this

    return inspection.closingReason?.color
  }

  get hasQualification () {
    const { inspection } = this

    return isValidNumber(inspection.inspectorQualification) && isValidNumber(inspection.supervisorQualification)
  }
  }
