
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { Debounce } from '@/utils/decorators'
  import KeyContent from '@/components/forms/inspection/steppers/content/equipment/basic/KeyContent.vue'
  import CarExhaustContent from '@/components/forms/inspection/steppers/content/CarExhaustContent.vue'
  import _ from 'lodash'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  components: {
    RowData,
    GRadioButton,
    CarExhaustContent,
    KeyContent,
    RatingCell,
    GFiles,
    GFormSlot,
    BasePanel,
  },
})
  export default class ExteriorEquipmentSupervisorPanel extends BaseStepper {
  equipment = {
    present: [],
    extra: [],
  }

  total = null
  exclude = ['Llantas de aleación', 'Tapas de ruedas', 'Pernos de seguridad']
  extraBackup = {}
  attributes = []
  extra = []
  fieldsKeys = ['status']
  validation = false
  cost: Record<string, any> = {}
  formDataExtra: Record<string, any> = {}
  errorIdentifierMessageExtra: Record<string, any> = {}
  extraEquipment = []
  formData: Record<string, any> = {}
  errorIdentifierMessage: Record<string, any> = {}

  ready = false
  readyExtra = false

  showIcon: Record<string, { yes: boolean, no: boolean }> = {};

  displayField (component, field, val) {
    if (!component || !field) return false
    const { getQuestionOptionsScoreNotZero } = this
    const displayField = field === getQuestionOptionsScoreNotZero(component, 1)[0].id

    if (!displayField) {
      this.formDataExtra[val].cost = 0
    }
    return displayField
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get aestheticEquipment () {
    return this.findComponentBySlug(this.components, 'aesthetic_equipment')
  }

  async sendExtra (total) {
    const { equipment, inspection, categoryName, category, formDataExtra } = this

    let error = false

    const keys = equipment.extra.map(item => item.value)

    for (const key of keys) {
      if (formDataExtra?.[key]?.cost && (formDataExtra[key].supervisorCost === null || formDataExtra[key].supervisorCost === undefined)) {
        this.errorIdentifierMessageExtra[key].status = 'El costo es requerido'
        error = true
      } else if (this.errorIdentifierMessageExtra?.[key]) {
        this.errorIdentifierMessageExtra[key].status = ''
      }
    }
    if (error) return

    await Promise.all(keys.map(async key => {
      const component = equipment.extra.find(item => item.value === key)
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)
        if (formDataExtra?.[key]?.supervisorCost) {
          await this.pushData({
            model: 'InspectionInspectedComponent',
            fields: { id: matchingInspectedComponent.id, supervisor_cost: formDataExtra[key].supervisorCost || 0 },
          })
        }
      }
    }))

    this.total = this.getTotalCost(this.formDataExtra, 'supervisorCost') + total
    this.$emit('input', { categoryName, category, total: this.total })
    this.$emit('inspection-category-done')
  }

  async send () {
    const { equipment, inspection, formData } = this

    let error = false
    const keys = equipment.present.map(item => item.value)

    for (const key of keys) {
      if (formData?.[key]?.cost && (formData[key].supervisorCost === null || formData[key].supervisorCost === undefined)) {
        this.errorIdentifierMessage[key] = 'El costo es requerido'
        error = true
      } else if (this.errorIdentifierMessage?.[key]) {
        this.errorIdentifierMessage[key] = ''
      }
    }
    if (error) return

    await Promise.all(keys.map(async key => {
      const component = equipment.present.find(item => item.value === key)
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)
        if (formData?.[key]?.supervisorCost) {
          await this.pushData({
            model: 'InspectionInspectedComponent',
            fields: { id: matchingInspectedComponent.id, supervisor_cost: formData[key].supervisorCost || 0 },
          })
        }
      }
    }))

    this.total = this.getTotalCost(this.formData, 'supervisorCost')
    await this.sendExtra(this.total)
  }

  get dataConsolidated () {
    const { aestheticEquipment, generation } = this

    return {
      equipment: aestheticEquipment,
      generation,
    }
  }

  @Watch('dataConsolidated', { immediate: true, deep: true })
  onComfortChange (val) {
    if (!val?.generation || !val?.equipment) return null
    const { equipment, exclude } = this

    if (equipment?.present.length && equipment.extra?.length) return null
    const { present, extra } = this.separateValues(val, 'Equipamiento exterior')

    this.equipment = {
      present: present.filter(item => !exclude.includes(item.value)),
      extra: extra.filter(item => !exclude.includes(item.value)),
    }
    this.generateFormInfo(this.equipment.present)
  }

  generateFormInfo (components) {
    const { ready } = this
    if (ready) return

    for (const field of components) {
      this.$set(this.formData, field.value, {
        status: {
          answer: null,
        },
        cost: 0,
        comment: '',
        supervisorCost: null,
        photo: [],
      })

      this.$set(this.errorIdentifierMessage, field.value, {
        status: '',
      })

      this.$set(this.cost, field.value, false)
    }

    this.ready = true
  }

  async edit (component, cost, data) {
    const { statusData } = data

    const parameter = component.findInspectionParameterByOrder(1)
    if (parameter.assessment.id !== statusData) {
      const fields = {
        id: parameter.id,
        id_inspection_assessment: statusData,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: { id: component.id, cost },
    })
  }

  get allData () {
    const { ready, selfPanel } = this

    return {
      ready, selfPanel,
    }
  }

  @Watch('allData', { immediate: true, deep: true })
  @Debounce(100)
  async onReadyChange (val) {
    if (!val.ready || !val.selfPanel) return

    const { idProcess, inspection, autoClose, disabled, equipment, displayQualification } = this

    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        this.formData[key].status.answer = matchingInspectedComponent.findInspectionParameterByOrder(1).assessment.id
        this.formData[key].cost = matchingInspectedComponent.cost
        this.formData[key].supervisorCost = isValidNumber(matchingInspectedComponent.supervisorCost) ? matchingInspectedComponent.supervisorCost : matchingInspectedComponent.cost
        this.formData[key].comment = matchingInspectedComponent.comment
      }
    }

    for (const value of equipment.present) {
      const index = equipment.present.indexOf(value)
      await handleComponentChange(value, index + 2, value.value)
    }
    if (this.step > equipment.present.length && !displayQualification) {
      await this.loadExtraInfo()
    }

    if (displayQualification) {
      await this.loadExtraInfo()
    }
  }

  async loadExtraInfo () {
    const { idProcessInspection, inspection, aestheticEquipment } = this
    this.attributes = await this.getDealAutoAttribute(aestheticEquipment.id, idProcessInspection, inspection.id)
    this.extra = this.attributes?.filter(att => !this.exclude.includes(att.val)).map(att => att.componentValue.id)

    if (this.extra?.length) {
      this.displayFields()
    }
  }

  processFormData (field, order, component) {
    const param = component.findInspectionParameterByOrder(order)
    this.formData[field].answer = param?.assessment?.id
  }

  displayFields () {
    const { extra, equipment } = this
    this.readyExtra = false
    if (!extra?.length) return null

    this.extraEquipment = equipment.extra.filter(item => extra.includes(item.id))
    const keys = Object.keys(this.formDataExtra)

    if (keys.length) {
      this.extraBackup = _.cloneDeep(this.formDataExtra)
    }

    this.formDataExtra = {}

    for (const field of this.extraEquipment) {
      const backupData = this.extraBackup[field.value] || {
        status: {
          answer: null,
        },
        cost: 0,
        comment: '',
        supervisorCost: null,
        photo: [],
      }

      this.$set(this.formDataExtra, field.value, backupData)

      this.$set(this.errorIdentifierMessageExtra, field.value, {
        status: '',
      })
    }

    this.readyExtra = true
  }

  @Watch('readyExtra', { immediate: true })
  @Debounce(100)
  async onReadyExtraChange (val) {
    if (!val) return

    const { idProcess, inspection, extraEquipment, displayQualification } = this

    const handleComponentChange = async (component, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        this.formDataExtra[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        this.formDataExtra[key].status.answer = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.assessment?.id
        this.formDataExtra[key].cost = matchingInspectedComponent.cost
        this.formDataExtra[key].supervisorCost = matchingInspectedComponent.supervisorCost || matchingInspectedComponent.cost
        this.formDataExtra[key].comment = matchingInspectedComponent.comment
      }
    }

    for (const value of extraEquipment) {
      await handleComponentChange(value, value.value)
    }
    const last = extraEquipment?.length - 1
    if (this.formDataExtra?.[extraEquipment?.[last]?.value]?.status.answer && !displayQualification) {
      this.step = this.step + 1
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  get forms () {
    const { formData, formDataExtra } = this

    return {
      formData,
      formDataExtra,
    }
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    if (!val?.id) return
    const { categoryName } = this
    const totalSupervisor = val.supervisorMetadata?.findCategoryByName(categoryName)?.total
    const totalInspector = val.metadata?.findCategoryByName(categoryName)?.total
    this.total = totalSupervisor || totalInspector
  }

  get noEquipment () {
    const { equipment, extraEquipment } = this

    return !equipment?.present?.length && !extraEquipment?.length
  }
  }
