
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { Component as VehicleComponent, Generation } from '@/entities/vehicle'
  import { Inspection } from '@/entities/purchase'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import ManualContent from '@/components/forms/inspection/steppers/content/transmition/ManualContent.vue'
  import AutomaticContent from '@/components/forms/inspection/steppers/content/transmition/AutomaticContent.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import AutomaticSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/transmition/AutomaticSupervisorContent.vue'
  import ManualSupervisorContent
    from '@/components/forms/inspection/supervisor/content/mechanical/transmition/ManualSupervisorContent.vue'
  import { fixPrice } from '@/utils/general'

@Component({
  methods: { fixPrice },
  components: { ManualSupervisorContent, AutomaticSupervisorContent, RowData, AutomaticContent, ManualContent },
})
  export default class TransmissionSupervisorContent extends FilesProcess {
  @Prop({ type: Object, default: () => null }) transmission: VehicleComponent
  @Prop({ type: Object, default: () => ({}) }) photoProperties: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) inspection: Inspection
  @Prop({ type: Object, default: () => ({}) }) generation: Generation
  @Prop({ type: Number, default: null }) idProcess: number
  @Prop({ type: Boolean, default: false }) disabled: boolean
  @Prop({ type: Boolean, default: false }) summary: boolean

  componentValue = null
  total = null

  declare $refs: {
    automatic: AutomaticSupervisorContent,
    manual: ManualSupervisorContent,
  };

  get allData () {
    const { transmission, generation } = this

    return {
      transmission,
      generation,
    }
  }

  @Watch('allData', { immediate: true, deep: true })
  async onCategoryChange (val) {
    this.componentValue = val.transmission?.values?.find(value => value.id === val?.generation?.transmission?.componentValue?.id)
  }

  get isManual () {
    return Boolean(this.componentValue?.isManual)
  }

  @Watch('total', { immediate: true })
  onTotalChange (val) {
    this.$emit('input', { total: val })
  }

  getValue (data, field) {
    return data?.[field]
  }

  async send () {
    let total = 0
    for (const key of ['automatic', 'manual']) {
      if (this.$refs[key]?.send()) {
        total = await this.$refs[key].send()
      }
    }

    return total
  }
  }
