
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { FileProcess } from '@/entities/files'
  import GImg from '@/components/core/files/GImg.vue'

@Component({
  components: { GImg },
})
  export default class FileView extends Vue {
  @Prop({ type: [String, Number], default: 185 }) readonly size!: string | number;
  @Prop({ type: Array, default: () => [] }) readonly file!: FileProcess[];
  }
