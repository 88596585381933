
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  methods: { isValidNumber },
  components: { RowData, RatingCell, GFiles, BasePanel, GFormSlot },
})
  export default class BodyWorkSupervisorPanel extends BaseStepper {
  componentKeys = [
    'frontBumper', 'carHood', 'leftFrontFender', 'leftDoor', 'leftRocker', 'leftRearDoor', 'leftRearFender', 'trunkTailgate', 'rearBumper',
    'rightRearFender', 'rightRearDoor', 'rightRocker', 'rightDoor', 'rightFrontFender', 'carRoof', 'chassis',
  ]

  fieldsKeys = ['original', 'paintOriginal', 'status']
  validation = false
  total = null

  formData = {
    frontBumper: {
      original: {
        answer: null,
      },
      paintOriginal: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    carHood: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    leftFrontFender: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    leftDoor: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    leftRocker: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    leftRearDoor: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    leftRearFender: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    trunkTailgate: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rearBumper: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rightRearFender: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rightRearDoor: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rightRocker: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rightDoor: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rightFrontFender: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    carRoof: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    chassis: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintOriginal: {
        answer: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    frontBumper: '',
    carHood: '',
    leftFrontFender: '',
    leftDoor: '',
    leftRocker: '',
    leftRearDoor: '',
    leftRearFender: '',
    trunkTailgate: '',
    rearBumper: '',
    rightRearFender: '',
    rightRearDoor: '',
    rightRocker: '',
    rightDoor: '',
    rightFrontFender: '',
    carRoof: '',
    chassis: '',
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get statusItems () {
    const {
      frontBumper,
      carHood,
      leftFrontFender,
      leftDoor,
      leftRocker,
      leftRearDoor,
      leftRearFender,
      trunkTailgate,
      rearBumper,
      rightRearFender,
      rightRearDoor,
      rightRocker,
      rightDoor,
      rightFrontFender,
      carRoof,
      chassis,
    } = this

    return {
      frontBumper: frontBumper.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      carHood: carHood.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftFrontFender: leftFrontFender.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftDoor: leftDoor.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftRocker: leftRocker.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftRearDoor: leftRearDoor.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      leftRearFender: leftRearFender.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      trunkTailgate: trunkTailgate.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rearBumper: rearBumper.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightRearFender: rightRearFender.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightRearDoor: rightRearDoor.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightRocker: rightRocker.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightDoor: rightDoor.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      rightFrontFender: rightFrontFender.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      carRoof: carRoof.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
      chassis: chassis.findQuestion(3).inspectionAssessments.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      frontBumper,
      carHood,
      leftFrontFender,
      leftDoor,
      leftRocker,
      leftRearDoor,
      leftRearFender,
      trunkTailgate,
      rearBumper,
      rightRearFender,
      rightRearDoor,
      rightRocker,
      rightDoor,
      rightFrontFender,
      carRoof,
      chassis,
    } = this
    return {
      frontBumper: this.getQuestionOptions(frontBumper, 3).find(item => item.score === 0),
      carHood: this.getQuestionOptions(carHood, 3).find(item => item.score === 0),
      leftFrontFender: this.getQuestionOptions(leftFrontFender, 3).find(item => item.score === 0),
      leftDoor: this.getQuestionOptions(leftDoor, 3).find(item => item.score === 0),
      leftRocker: this.getQuestionOptions(leftRocker, 3).find(item => item.score === 0),
      leftRearDoor: this.getQuestionOptions(leftRearDoor, 3).find(item => item.score === 0),
      leftRearFender: this.getQuestionOptions(leftRearFender, 3).find(item => item.score === 0),
      trunkTailgate: this.getQuestionOptions(trunkTailgate, 3).find(item => item.score === 0),
      rearBumper: this.getQuestionOptions(rearBumper, 3).find(item => item.score === 0),
      rightRearFender: this.getQuestionOptions(rightRearFender, 3).find(item => item.score === 0),
      rightRearDoor: this.getQuestionOptions(rightRearDoor, 3).find(item => item.score === 0),
      rightRocker: this.getQuestionOptions(rightRocker, 3).find(item => item.score === 0),
      rightDoor: this.getQuestionOptions(rightDoor, 3).find(item => item.score === 0),
      rightFrontFender: this.getQuestionOptions(rightFrontFender, 3).find(item => item.score === 0),
      carRoof: this.getQuestionOptions(carRoof, 3).find(item => item.score === 0),
      chassis: this.getQuestionOptions(chassis, 3).find(item => item.score === 0),
    }
  }

  get chassis () {
    return this.findComponentBySlug(this.components, 'chassis')
  }

  get carRoof () {
    return this.findComponentBySlug(this.components, 'car_roof')
  }

  get rightFrontFender () {
    return this.findComponentBySlug(this.components, 'right_front_fender')
  }

  get rightDoor () {
    return this.findComponentBySlug(this.components, 'right_door')
  }

  get rightRocker () {
    return this.findComponentBySlug(this.components, 'right_rocker')
  }

  get rightRearDoor () {
    return this.findComponentBySlug(this.components, 'right_rear_door')
  }

  get rightRearFender () {
    return this.findComponentBySlug(this.components, 'right_rear_fender')
  }

  get rearBumper () {
    return this.findComponentBySlug(this.components, 'rear_bumper')
  }

  get trunkTailgate () {
    return this.findComponentBySlug(this.components, 'trunk_tailgate')
  }

  get leftRearFender () {
    return this.findComponentBySlug(this.components, 'left_rear_fender')
  }

  get leftRearDoor () {
    return this.findComponentBySlug(this.components, 'left_rear_door')
  }

  get leftRocker () {
    return this.findComponentBySlug(this.components, 'left_rocker')
  }

  get leftDoor () {
    return this.findComponentBySlug(this.components, 'left_door')
  }

  get leftFrontFender () {
    return this.findComponentBySlug(this.components, 'left_front_fender')
  }

  get frontBumper () {
    return this.findComponentBySlug(this.components, 'front_bumper')
  }

  get carHood () {
    return this.findComponentBySlug(this.components, 'car_hood')
  }

  async send () {
    const { allComponents, inspection, categoryName, category, formData } = this

    let error = false
    const keys = Object.keys(allComponents)

    for (const key of keys) {
      if (formData?.[key]?.cost && (formData[key].supervisorCost === null || formData[key].supervisorCost === undefined)) {
        this.errorIdentifierMessage[key] = 'El costo es requerido'
        error = true
      } else {
        this.errorIdentifierMessage[key] = ''
      }
    }

    if (error) return
    await Promise.all(keys.map(async key => {
      const component = allComponents[key]
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(2)?.[0]?.id)
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(3)?.[0]?.id)

        if (formData?.[key]?.supervisorCost) {
          await this.pushData({
            model: 'InspectionInspectedComponent',
            fields: { id: matchingInspectedComponent.id, supervisor_cost: formData[key].supervisorCost || 0 },
          })
        }
      }
    }))
    this.total = this.getTotalCost(this.formData, 'supervisorCost')
    this.$emit('input', { categoryName, category, total: this.total })
    this.$emit('inspection-category-done')
  }

  get allComponents () {
    const {
      frontBumper,
      carHood,
      leftFrontFender,
      leftDoor,
      leftRocker,
      leftRearDoor,
      leftRearFender,
      trunkTailgate,
      rearBumper,
      rightRearFender,
      rightRearDoor,
      rightRocker,
      rightDoor,
      rightFrontFender,
      carRoof,
      chassis,
      selfPanel,
    } = this

    return {
      frontBumper,
      carHood,
      leftFrontFender,
      leftDoor,
      leftRocker,
      leftRearDoor,
      leftRearFender,
      trunkTailgate,
      rearBumper,
      rightRearFender,
      rightRearDoor,
      rightRocker,
      rightDoor,
      rightFrontFender,
      carRoof,
      chassis,
      selfPanel,
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { idProcess, inspection, componentKeys, autoClose, disabled, selfPanel, displayQualification } = this

    if (!selfPanel) {
      return
    }
    this.step = 1
    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        if (this.formData[key].paintOriginal) {
          this.formData[key].paintOriginal.answer = matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.id
        }
        this.formData[key].original.answer = matchingInspectedComponent.findInspectionParameterByOrder(1).assessment.id

        if (!matchingInspectedComponent.findInspectionParameterByOrder(3)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(3)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(3).map(item => item.assessment.id)
        }

        this.formData[key].cost = matchingInspectedComponent.cost
        this.formData[key].supervisorCost = isValidNumber(matchingInspectedComponent.supervisorCost) ? matchingInspectedComponent.supervisorCost : matchingInspectedComponent.cost
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val[key], index + 1, key)
    }
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    if (!val?.id) return
    const { category } = this

    const totalSupervisor = val.supervisorMetadata?.findCategoryByName(category.name)?.total
    const totalInspector = val.metadata?.findCategoryByName(category.name)?.total
    this.total = totalSupervisor || totalInspector
  }
  }
