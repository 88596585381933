
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import { FileProcess } from '@/entities/files'

@Component({
  components: { RowData, RatingCell, GFiles, BasePanel, GFormSlot },
})
  export default class PhotosReportPanel extends BaseStepper {
  @Prop({ type: Array, required: true }) fileParameters!: any
  componentKeys = []
  validation = false
  ready = false
  loaded = false
  formData: FileProcess[] = []

  async mounted () {
    this.componentKeys = this.fileParameters.sort((a, b) => a.id - b.id)
  }

  get readyView () {
    const { ready, selfPanel } = this

    return {
      ready,
      selfPanel,
    }
  }

  @Watch('readyView', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { fileParameters, inspection } = this

    if (!val.selfPanel) {
      return
    }

    const handleComponentChange = async parameter => {
      const photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: inspection.id } },
            { id_file_parameter: { _eq: parameter.id } },
          ],
        },
        force: true,
      })

      const newPhoto = []
      photo.forEach((element: FileProcess) => {
        element.description = parameter.description
        newPhoto.push(element)
      })

      this.formData.push(...newPhoto)
    }

    for (const parameter of fileParameters) {
      await handleComponentChange(parameter)
    }

    this.loaded = true
  }

  get viewStep () {
    const { step, componentKeys } = this

    return {
      step,
      componentKeys,
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }
  }
