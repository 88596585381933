
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { ContentBase } from '@/components/forms/inspection/steppers/content/ContentBase'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { sortArray } from '@/utils/array/manipulation'
  import { Debounce } from '@/utils/decorators'
  import { Component as CategoryComponent } from '@/entities/vehicle'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  components: { RowData, GFiles, GFormSlot },
  methods: {
    isValidNumber,
    sortArray,
  },
})
  export default class ScannerSupervisorContent extends ContentBase {
  @Prop({ type: Object }) scanner: CategoryComponent
  items = []
  fieldsKeys = ['noise', 'status']

  formData = {
    noise: {
      answer: null,
      provisional: null,
      comment: undefined,
    },
    status: {
      answer: null,
      provisional: null,
    },
    cost: undefined,
    supervisorCost: undefined,
    photo: null,
  }

  errorIdentifierMessage = ''

  async send () {
    const { formData, scanner, inspection } = this

    if (formData.cost && !isValidNumber(formData.supervisorCost)) {
      this.errorIdentifierMessage = 'El costo es requerido'
      return false
    } else {
      this.errorIdentifierMessage = ''
    }

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === scanner?.inspectionComponent?.id
    )
    if (matchingInspectedComponent) {
      await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)

      if (formData?.supervisorCost) {
        await this.pushData({
          model: 'InspectionInspectedComponent',
          fields: { id: matchingInspectedComponent.id, supervisor_cost: formData.supervisorCost || 0 },
        })
      }
    }

    return parseInt(this.formData.supervisorCost)
  }

  @Watch('scanner', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { idProcess, inspection } = this

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === val?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      this.formData.photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: matchingInspectedComponent.id } },
            { parameter: { process: { id: { _eq: idProcess } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })

      this.formData.noise.answer = matchingInspectedComponent?.findInspectionQualificationByOrder(1)?.[0]?.assessment?.id
      this.processFormData('status', 2, matchingInspectedComponent)
      this.formData.noise.comment = matchingInspectedComponent.comment
      this.formData.cost = matchingInspectedComponent.cost

      this.formData.supervisorCost = isValidNumber(matchingInspectedComponent.supervisorCost) ? matchingInspectedComponent.supervisorCost : matchingInspectedComponent.cost
      this.$emit('input', { total: this.formData.cost })
    }
  }

  processFormData (field, order, component) {
    const param = component.findInspectionParameterByOrder(order)
    const qual = component.findInspectionQualificationByOrder(order)

    if (!param?.assessment?.score) {
      this.formData[field].provisional = param?.assessment?.id
      this.formData[field].answer = null
    } else {
      this.formData[field].provisional = false
      this.formData[field].answer = qual.map(item => item.assessment.id)
    }
  }

  get displayField () {
    const { formData, scanner } = this
    if (!scanner) return false

    const isDisplayField = formData.noise.answer === this.getQuestionOptionScoreZero(scanner, 1)[0].id

    if (isDisplayField) {
      this.formData.noise.comment = undefined
    }
    return isDisplayField
  }

  @Watch('formData.noise.answer', { immediate: true })
  onNoiseChange (val) {
    if (val && this.getQuestionOptionsScoreNotZero(this.scanner, 1)[0].id === val) {
      this.formData.status.answer = []
      this.formData.status.provisional = this.getQuestionOptionScoreZero(this.scanner, 2)[0].id
    }
  }

  get displayComment () {
    const { formData: { noise }, scanner, getQuestionOptionsScoreNotZero } = this

    return noise.answer === getQuestionOptionsScoreNotZero(scanner, 1)?.[0].id
  }

  get requiredPhoto () {
    const { formData: { noise }, scanner, getQuestionOptionScoreZero } = this

    return noise.answer === getQuestionOptionScoreZero(scanner, 1)?.[0].id
  }
  }
