
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { RULES } from '@/components/forms'
  import { isValidNumber, minLength } from '@/utils/general'
  import { ComponentCategory, ComponentValue } from '@/entities/vehicle'
  import { sortArray } from '@/utils/array/manipulation'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'

@Component({
  methods: { sortArray, minLength, isValidNumber },
  components: { RowData, RatingCell, GFiles, BasePanel, GFormSlot },
})
  export default class TireAndWheelSupervisorPanel extends BaseStepper {
  @Prop({ type: Object }) exterior!: ComponentCategory
  equipment = {
    present: [],
    extra: [],
  }

  total = null
  initStep = 1
  alloyWheel: ComponentValue = null
  wheelCovers: ComponentValue = null
  capScrew: ComponentValue = null
  equipmentKeys = {
    alloyWheel: 'Llantas de aleación',
    wheelCovers: 'Tapas de ruedas',
    capScrew: 'Pernos de seguridad',
  }

  fieldsEquipment = ['alloyWheel', 'wheelCovers', 'capScrew']
  attributes = []
  componentKeys = [
    'alloyWheel', 'wheelCovers', 'capScrew', 'rightFrontTire', 'rightFrontWheel', 'leftFrontTire', 'leftFrontWheel', 'leftRearTire', 'leftRearWheel', 'rightRearTire', 'rightRearWheel',
    'spareTire', 'spareWheel']

  fieldsKeys = ['original', 'level', 'status', 'new']
  rims = Array.from({ length: 11 }, (_, i) => `R${i + 13}`)

  multipleOfFive = RULES.isMultipleOfFive
  removeSpareOption = false
  validation = false
  loading = true

  formData: Record<string, any> = {
    alloyWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    wheelCovers: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    capScrew: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    rightFrontTire: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    rightFrontWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      new: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
    rightRearTire: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      paintStatus: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    rightRearWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      new: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
    leftRearTire: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    leftRearWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      new: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
    leftFrontTire: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    leftFrontWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      new: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
    spareTire: {
      should: {
        answer: null,
      },
      present: {
        answer: null,
      },
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
    },
    spareWheel: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      level: {
        answer: null,
      },
      cost: undefined,
      photo: null,
      supervisorCost: undefined,
      detail: {
        brand: null,
        width: null,
        height: null,
        diameter: null,
      },
    },
  }

  alertWheels = {
    front: '',
    rear: '',
  }

  errorIdentifierMessage = {
    alloyWheel: '',
    wheelCovers: '',
    capScrew: '',
    rightFrontTire: '',
    rightFrontWheel: '',
    rightRearTire: '',
    rightRearWheel: '',
    leftRearTire: '',
    leftRearWheel: '',
    leftFrontTire: '',
    leftFrontWheel: '',
    spareTire: '',
    spareWheel: '',
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get aestheticEquipment () {
    return this.findComponentBySlug(this.exterior.components, 'aesthetic_equipment')
  }

  get statusItems () {
    const {
      rightFrontTire,
      rightFrontWheel,
      rightRearTire,
      rightRearWheel,
      leftRearTire,
      leftRearWheel,
      leftFrontTire,
      leftFrontWheel,
      spareTire,
      spareWheel,
    } = this

    return {
      rightFrontTire: rightFrontTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rightFrontWheel: rightFrontWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
      rightRearTire: rightRearTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rightRearWheel: rightRearWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
      leftRearTire: leftRearTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      leftRearWheel: leftRearWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
      leftFrontTire: leftFrontTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      leftFrontWheel: leftFrontWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
      spareTire: spareTire.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      spareWheel: spareWheel.findQuestion(6).inspectionAssessments.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      rightFrontTire,
      rightFrontWheel,
      rightRearTire,
      rightRearWheel,
      leftRearTire,
      leftRearWheel,
      leftFrontTire,
      leftFrontWheel,
      spareTire,
      spareWheel,
    } = this
    return {
      rightFrontTire: rightFrontTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rightFrontWheel: rightFrontWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
      rightRearTire: rightRearTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rightRearWheel: rightRearWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
      leftRearTire: leftRearTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      leftRearWheel: leftRearWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
      leftFrontTire: leftFrontTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      leftFrontWheel: leftFrontWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
      spareTire: spareTire.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      spareWheel: spareWheel.findQuestion(6).inspectionAssessments.find(item => item.score === 0),
    }
  }

  get spareWheel () {
    return this.findComponentBySlug(this.components, 'spare_wheel')
  }

  get spareTire () {
    return this.findComponentBySlug(this.components, 'spare_tire')
  }

  get leftFrontWheel () {
    return this.findComponentBySlug(this.components, 'left_front_wheel')
  }

  get leftFrontTire () {
    return this.findComponentBySlug(this.components, 'left_front_tire')
  }

  get leftRearWheel () {
    return this.findComponentBySlug(this.components, 'left_rear_wheel')
  }

  get leftRearTire () {
    return this.findComponentBySlug(this.components, 'left_rear_tire')
  }

  get rightRearWheel () {
    return this.findComponentBySlug(this.components, 'right_rear_wheel')
  }

  get rightRearTire () {
    return this.findComponentBySlug(this.components, 'right_rear_tire')
  }

  get rightFrontWheel () {
    return this.findComponentBySlug(this.components, 'right_front_wheel')
  }

  get rightFrontTire () {
    return this.findComponentBySlug(this.components, 'right_front_tire')
  }

  async send () {
    const { allComponents, inspection, categoryName, category, formData, isSpare, removeSpareOption, alertWheels } = this

    let error = false
    const keys = Object.keys(allComponents)

    if (!isSpare && !removeSpareOption) {
      keys.splice(keys.indexOf('spareTire'), 1)
      keys.splice(keys.indexOf('spareWheel'), 1)
    }

    for (const key of keys) {
      if (formData?.[key]?.cost && !isValidNumber(formData[key].supervisorCost)) {
        this.errorIdentifierMessage[key] = 'El costo es requerido'
        error = true
      } else if ((key.toLowerCase().includes('frontwheel') || key.toLowerCase().includes('rearwheel')) && (formData[key].supervisorCost === null || formData[key].supervisorCost === undefined)) {
        if (key.toLowerCase().includes('frontwheel') && alertWheels.front !== '') {
          this.errorIdentifierMessage[key] = 'El costo es requerido'
          error = true
        }
        if (key.toLowerCase().includes('rearwheel') && alertWheels.rear !== '') {
          this.errorIdentifierMessage[key] = 'El costo es requerido'
          error = true
        }
      } else {
        this.errorIdentifierMessage[key] = ''
      }
    }

    if (error) return

    await Promise.all(keys.map(async key => {
      const component = allComponents[key]
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)
        if (formData?.[key]?.supervisorCost) {
          await this.pushData({
            model: 'InspectionInspectedComponent',
            fields: { id: matchingInspectedComponent.id, supervisor_cost: formData[key].supervisorCost || 0 },
          })
        }
      }
    }))

    this.total = this.getTotalCost(this.formData, 'supervisorCost')
    this.$emit('input', { categoryName, category, total: this.total })
    this.$emit('inspection-category-done')
  }

  get allComponents () {
    const {
      rightFrontTire,
      rightFrontWheel,
      rightRearTire,
      rightRearWheel,
      leftRearTire,
      leftRearWheel,
      leftFrontTire,
      leftFrontWheel,
      spareTire,
      spareWheel,
      selfPanel,
      alloyWheel,
      wheelCovers,
      capScrew,
    } = this

    return {
      alloyWheel,
      wheelCovers,
      capScrew,
      rightFrontTire,
      rightFrontWheel,
      rightRearTire,
      rightRearWheel,
      leftRearTire,
      leftRearWheel,
      leftFrontTire,
      leftFrontWheel,
      spareTire,
      spareWheel,
      selfPanel,
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification, initStep } = this
    this.step = 1
    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          const init = initStep === 1 ? 3 : 4
          if (step > init) {
            if (step === init + 1 || step === init + 2) {
              this.step = step
            }
            if (step === init + 4) {
              this.step = initStep === 1 ? 7 : 8
            }
          } else {
            this.step = step
          }
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        this.setData(matchingInspectedComponent, key, component)
        this.formData[key].cost = matchingInspectedComponent.cost
        this.formData[key].supervisorCost = isValidNumber(matchingInspectedComponent.supervisorCost) ? matchingInspectedComponent.supervisorCost : matchingInspectedComponent.cost
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val[key], index + 1, key)
    }

    this.loading = false
  }

  setData (matchingInspectedComponent, key, component) {
    if (this.fieldsEquipment.includes(key)) {
      this.formData[key].original.answer = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.assessment?.id
      if (matchingInspectedComponent?.findInspectionParameterByOrder(2)?.assessment?.id) {
        this.formData[key].status.answer = matchingInspectedComponent.findInspectionParameterByOrder(2).assessment?.id
      }
      return
    }

    if (key.includes('Tire')) {
      if (key.includes('spare')) {
        this.setSpareTire(matchingInspectedComponent, key)
      } else {
        this.formData[key].original.answer = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.assessment?.id
        if (!matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(2).map(item => item.assessment.id)
        }
      }
    } else {
      this.setWheelData(matchingInspectedComponent, key, component)
    }
  }

  setSpareTire (matchingInspectedComponent, key) {
    this.formData[key].should.answer = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.assessment?.id
    this.formData[key].present.answer = matchingInspectedComponent?.findInspectionParameterByOrder(2)?.assessment?.id
    this.formData[key].original.answer = matchingInspectedComponent?.findInspectionParameterByOrder(3)?.assessment?.id
    if (!matchingInspectedComponent.findInspectionParameterByOrder(4)?.assessment?.score) {
      this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(4)?.assessment?.id
      this.formData[key].status.answer = null
    } else {
      this.formData[key].status.provisional = false
      this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(4).map(item => item.assessment.id)
    }
  }

  setWheelData (matchingInspectedComponent, key, component) {
    this.formData[key].detail.brand = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.value
    this.formData[key].detail.height = matchingInspectedComponent?.findInspectionParameterByOrder(2)?.value
    this.formData[key].detail.width = matchingInspectedComponent?.findInspectionParameterByOrder(3)?.value
    this.formData[key].detail.diameter = matchingInspectedComponent?.findInspectionParameterByOrder(4)?.value
    this.formData[key].original.answer = matchingInspectedComponent?.findInspectionParameterByOrder(5)?.assessment?.id
    const idAnswer = matchingInspectedComponent.findInspectionParameterByOrder(6)?.assessment?.id

    this.formData[key].level.answer = sortArray(component.inspectionComponent.getComponentOptionByOrder(6), 'name', true, '%').findIndex(option => {
      return option.id === idAnswer
    })

    if (!matchingInspectedComponent.findInspectionParameterByOrder(7)?.assessment?.score) {
      this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(7)?.assessment?.id
      this.formData[key].status.answer = null
    } else {
      this.formData[key].status.provisional = false
      this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(7).map(item => item.assessment.id)
    }

    if ('new' in this.formData[key]) {
      this.formData[key].new.answer = matchingInspectedComponent?.findInspectionParameterByOrder(8)?.assessment?.id
    }
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  getDescriptionWheel (data) {
    if (!data?.height || !data?.width || !data?.diameter) return null
    return `Medida: ${data.height}/${data.width}/${data.diameter}`
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('inspection', { immediate: true, deep: true })
  async onInspectionChange (val): Promise<void> {
    const { selfPanel } = this
    if (!val?.id && !selfPanel) return

    const { idProcessInspection, aestheticEquipment } = this
    this.attributes = await this.getDealAutoAttribute(aestheticEquipment.id, idProcessInspection, val.id)
  }

  get isAlloyWheel () {
    const { generation, equipmentKeys, getQuestionOption, wheelCovers } = this
    if (!generation) return null

    const isPresent = generation?.attributes?.find(val => val.val === equipmentKeys.alloyWheel)
    if (isPresent) {
      this.formData.alloyWheel.original.answer = this.questionYes?.id
      this.formData.wheelCovers.original.answer = getQuestionOption(wheelCovers, 1, true, 'No').id
    }
    return Boolean(isPresent)
  }

  @Watch('aestheticEquipment', { immediate: true, deep: true })
  async onComfortChange (val) {
    if (!val) return null
    const { equipmentKeys } = this

    this.alloyWheel = val.values.find(item => item.value === equipmentKeys.alloyWheel)
    this.wheelCovers = val.values.find(item => item.value === equipmentKeys.wheelCovers)
    this.capScrew = val.values.find(item => item.value === equipmentKeys.capScrew)
  }

  get questionYes () {
    const { getQuestionOptionScoreZero, alloyWheel } = this

    return getQuestionOptionScoreZero(alloyWheel, 1)?.find(item => item.name === 'Si')
  }

  get questionAlloyYes () {
    const { getQuestionOptionScoreZero, alloyWheel } = this

    return getQuestionOptionScoreZero(alloyWheel, 2)?.find(item => item.name === 'Si')
  }

  get questionAlloyNo () {
    const { alloyWheel, getQuestionOption } = this

    if (this.formData.alloyWheel.original.answer === getQuestionOption(alloyWheel, 1).id) {
      return getQuestionOption(alloyWheel, 2, false, 'No')
    }

    if (this.formData.alloyWheel.original.answer === getQuestionOption(alloyWheel, 1, true, 'No').id) {
      return getQuestionOption(alloyWheel, 2, true, 'No aplica')
    }

    return { id: null }
  }

  get capScrewOption () {
    const { capScrew, getQuestionOption } = this

    if (this.formData.capScrew.original.answer === getQuestionOption(capScrew, 1).id) {
      return getQuestionOption(capScrew, 2, false, 'No')
    }

    if (this.formData.capScrew.original.answer === getQuestionOption(capScrew, 1, true, 'No').id) {
      return getQuestionOption(capScrew, 2, true, 'No aplica')
    }

    return { id: null }
  }

  get wheelCoverOption () {
    const { wheelCovers, getQuestionOption } = this

    if (this.formData.wheelCovers.original.answer === getQuestionOption(wheelCovers, 1).id) {
      return getQuestionOption(wheelCovers, 2, false, 'No')
    }

    if (this.formData.wheelCovers.original.answer === getQuestionOption(wheelCovers, 1, true, 'No').id) {
      return getQuestionOption(wheelCovers, 2, true, 'No aplica')
    }

    return { id: null }
  }

  get isNotAlloy () {
    const { formData: { alloyWheel } } = this

    return (alloyWheel.status.answer && alloyWheel.status.answer !== this.questionAlloyYes?.id)
  }

  @Watch('formData.alloyWheel.original.answer', { immediate: true, deep: true })
  onAlloyChange (val) {
    this.initValues(val, this.alloyWheel, 'alloyWheel')

    const { getQuestionOption, alloyWheel, wheelCovers } = this
    if (!this.loading) {
      this.formData.alloyWheel.status.answer = null
      this.formData.alloyWheel.cost = 0
    }

    if (val === getQuestionOption(alloyWheel, 1, true, 'Si').id) {
      this.formData.wheelCovers.original.answer = getQuestionOption(wheelCovers, 1, true, 'No').id
    }
  }

  @Watch('formData.wheelCovers.original.answer', { immediate: true, deep: true })
  onWheelCoversChange (val) {
    this.initValues(val, this.wheelCovers, 'wheelCovers')

    if (!this.loading) {
      this.formData.wheelCovers.status.answer = null
      this.formData.capScrew.cost = 0
    }
  }

  @Watch('formData.capScrew.original.answer', { immediate: true, deep: true })
  onCapScrewChange (val) {
    this.initValues(val, this.capScrew, 'capScrew')
    if (!this.loading) {
      this.formData.capScrew.status.answer = null
      this.formData.capScrew.cost = 0
    }
  }

  initValues (val, component, field) {
    if (val === this.getQuestionOption(component, 1, true, 'No')?.id) {
      this.formData[field].cost = 0
    }
  }

  get isSpareCost () {
    const { formData: { spareTire }, getQuestionOption, spareTire: component } = this
    return spareTire.present.answer === getQuestionOption(component, 2, false, 'No').id
  }

  get isCategoryDone () {
    const { isMetadata, inspection, categoryName } = this
    return isMetadata && !inspection.metadata.findCategoryByName(categoryName).done && !inspection.metadata.findCategoryByName(categoryName)?.score
  }

  get wheelsDetails () {
    const { formData: { rightFrontWheel, leftFrontWheel, rightRearWheel, leftRearWheel }, getDescriptionWheel } = this

    return {
      rightFrontWheel: getDescriptionWheel(rightFrontWheel.detail) + ' ' + rightFrontWheel.detail.brand,
      leftFrontWheel: getDescriptionWheel(leftFrontWheel.detail) + ' ' + leftFrontWheel.detail.brand,
      rightRearWheel: getDescriptionWheel(rightRearWheel.detail) + ' ' + rightRearWheel.detail.brand,
      leftRearWheel: getDescriptionWheel(leftRearWheel.detail) + ' ' + leftRearWheel.detail.brand,
    }
  }

  @Watch('wheelsDetails', { immediate: true, deep: true })
  @Debounce(250)
  onWheelChange (val) {
    if (!val.rightFrontWheel.includes('null') && !val.leftFrontWheel.includes('null') && val.rightFrontWheel !== val.leftFrontWheel) {
      this.alertWheels.front = 'Los neumáticos delanteros no coinciden considere comprar un par nuevo'
    } else {
      this.alertWheels.front = ''
    }

    if (!val.rightRearWheel.includes('null') && !val.leftRearWheel.includes('null') && val.rightRearWheel !== val.leftRearWheel) {
      this.alertWheels.rear = 'Los neumáticos traseros no coinciden considere comprar un par nuevo'
    } else {
      this.alertWheels.rear = ''
    }
  }

  get isSpare () {
    const { formData: { spareTire }, getQuestionOption, spareTire: component } = this

    return spareTire.present.answer === getQuestionOption(component, 2)?.id
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    if (!val?.id) return
    const { category } = this

    if (!isValidNumber(this.total)) {
      const totalSupervisor = val.supervisorMetadata?.findCategoryByName(category.name)?.total
      const totalInspector = val.metadata.findCategoryByName(category.name)?.total
      this.total = totalSupervisor || totalInspector
    }
  }

  get isSpareWheel () {
    const { formData, getQuestionOption, spareTire } = this

    return formData.spareTire.should.answer === getQuestionOption(spareTire)?.id
  }

  @Watch('formData.spareTire.should.answer', { immediate: true, deep: true })
  onShouldChange (val) {
    if (!val) return
    const { displayQualification } = this
    if (val !== this.getQuestionOption(this.spareTire, 1, true, 'No')?.id && !displayQualification) {
      this.removeSpareOption = false
    }
  }
  }
