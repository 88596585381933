
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { ContentBase } from '@/components/forms/inspection/steppers/content/ContentBase'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { sortArray } from '@/utils/array/manipulation'
  import { isArray } from 'class-validator'
  import { Debounce } from '@/utils/decorators'
  import { getProgressColor } from '@/utils/general'
  import { Component as CategoryComponent } from '@/entities/vehicle'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, GFiles, GFormSlot },
  methods: {
    sortArray,
  },
})
  export default class BrakesContent extends ContentBase {
  @Prop({ type: Object }) brakes: CategoryComponent
  @Prop({ type: Boolean, default: false }) displayRating!: boolean
  items = []
  itemsLive = []
  itemsDisk = []
  fieldsKeys = ['level', 'live', 'diskLive', 'status', 'filter']
  loadingSend = false

  formData = {
    level: {
      answer: null,
    },
    live: {
      answer: null,
    },
    diskLive: {
      answer: null,
    },
    status: {
      answer: null,
      provisional: null,
    },

    filter: {
      answer: null,
      provisional: null,
    },
    cost: undefined,
    photo: null,
  }

  errorIdentifierMessage = {
    status: {
      status: '',
      provisional: '',
    },
    filter: {
      status: '',
      provisional: '',
    },
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  getName (items, field) {
    return items?.[field]?.name
  }

  getSendData (items, itemsLive, itemsDisk, data) {
    const levelData = items[data.level.answer]
    const liveData = itemsLive[data.live.answer]
    const diskData = itemsDisk[data.diskLive.answer]
    const statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer
    const filterData = data?.filter?.provisional ? [data.filter.provisional] : data.filter.answer
    return {
      levelData,
      liveData,
      diskData,
      statusData,
      filterData,
    }
  }

  async send () {
    const { formData: data } = this
    if (this.validateFields(data)) {
      return
    }
    this.loadingSend = true
    const {
      idProcess,
      idEmployee,
      inspection,
      brakes: component,
      photoProperties,
      items,
      cost,
      itemsLive,
      itemsDisk,
      idProcessInspection,
    } = this
    let resp

    const {
      levelData,
      liveData,
      diskData,
      statusData,
      filterData,
    } = this.getSendData(items, itemsLive, itemsDisk, data)

    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent
      await this.edit(resp, data.cost, { levelData, liveData, diskData, statusData, filterData })
    } else {
      const fields = {
        id_inspection: inspection.id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(1)?.id,
              id_inspection_assessment: levelData?.id,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(2)?.id,
              id_inspection_assessment: liveData?.id,
            },
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(3)?.id,
              id_inspection_assessment: diskData?.id,
            },
            ...statusData.map(status => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(4)?.id,
                id_inspection_assessment: status,
              }
            }),
            ...filterData.map(filter => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(5)?.id,
                id_inspection_assessment: filter,
              }
            }),
          ].filter(_ => _.id_inspection_assessment),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    if (!cost && data?.photo?.length) {
      await this.deleteFiles(data.photo)
    } else {
      await this.addFiles(data.photo, photoProperties, idProcess, resp)
    }
    this.$emit('input', { cost: this.formData.cost })
    this.$emit('next')
    this.$emit('update:inspection')
    this.loadingSend = false
  }

  @Watch('formData', { immediate: true, deep: true })
  @Debounce(100)
  onFormDataChange (val) {
    this.initiateErrorMessage()
    const { brakes, fieldsKeys, validation, items } = this
    if (validation) {
      this.validation = false
      return
    }
    this.cost = this.isAnyFieldValid(fieldsKeys, val, brakes)
    this.cost = this.cost || this.hasScore(val, items, ['level', 'live', 'diskLive'])

    if (!this.cost && val.cost) {
      this.formData.cost = 0
    }
  }

  hasScore (val, items, keys: string[]): boolean {
    for (const key of keys) {
      if (val[key]?.answer !== null && Boolean(items[val[key].answer]?.score)) {
        return true
      }
    }
    return false
  }

  initiateErrorMessage () {
    this.errorIdentifierMessage.status.status = ''
    this.errorIdentifierMessage.status.provisional = ''
    this.errorIdentifierMessage.filter.status = ''
    this.errorIdentifierMessage.filter.provisional = ''
  }

  setErrorMessage (field) {
    if (this.formData[field]?.provisional === null || this.formData[field]?.provisional === undefined) {
      this.errorIdentifierMessage[field].provisional = 'Este campo es requerido'
    } else if (this.formData[field]?.provisional !== null || this.formData[field]?.provisional !== undefined) {
      this.errorIdentifierMessage[field].provisional = ''
    }

    this.errorIdentifierMessage[field].status = 'Este campo es requerido'
  }

  validateFields (data) {
    const fields = ['status', 'filter']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(field)
        return true
      }
      this.errorIdentifierMessage[field].status = ''
      return false
    }

    for (const field of fields) {
      const isProvisional = 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional) {
        this.validation = true

        if (this.formData?.[field]?.answer) {
          this.formData[field].answer = []
        }
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        this.errorIdentifierMessage[field].status = ''
      }
    }

    return isValid
  }

  @Watch('brakes', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { idProcess, inspection, displayQualification } = this

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === val?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      this.formData.photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: matchingInspectedComponent.id } },
            { parameter: { process: { id: { _eq: idProcess } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })

      this.processFormData('status', 4, matchingInspectedComponent)
      this.processFormData('filter', 5, matchingInspectedComponent)

      this.formData.level.answer = this.items?.findIndex(item => item.id === matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.assessment?.id)
      this.formData.live.answer = this.itemsLive?.findIndex(item => item.id === matchingInspectedComponent.findInspectionQualificationByOrder(2)?.[0]?.assessment?.id)
      this.formData.diskLive.answer = this.itemsDisk?.findIndex(item => item.id === matchingInspectedComponent.findInspectionQualificationByOrder(3)?.[0]?.assessment?.id)
      this.formData.cost = matchingInspectedComponent.cost

      if (matchingInspectedComponent.cost) {
        this.cost = true
      }
    }
    this.$emit('input', { cost: this.formData.cost })
    const validation = ((this.formData.status.provisional === false && this.formData.status.answer) || (this.formData.status.provisional)) && !displayQualification
    if (validation && !this.displayRating) {
      this.$emit('next')
    }
  }

  processFormData (field, order, component) {
    const param = component.findInspectionParameterByOrder(order)
    const qual = component.findInspectionQualificationByOrder(order)

    if (!param?.assessment?.score) {
      this.formData[field].provisional = param?.assessment?.id
      this.formData[field].answer = null
    } else {
      this.formData[field].provisional = false
      this.formData[field].answer = qual.map(item => item.assessment.id)
    }
  }

  async edit (component, cost, data) {
    const { levelData, liveData, diskData, statusData, filterData } = data
    const { idEmployee } = this
    await this.editMultipleComponent(component, statusData, idEmployee, 4)
    await this.editMultipleComponent(component, filterData, idEmployee, 5)

    const parameter = component.findInspectionParameterByOrder(1)
    if (parameter.assessment.id !== levelData.id) {
      const fields = {
        id: parameter.id,
        id_inspection_assessment: levelData.id,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    const parameterLive = component.findInspectionParameterByOrder(2)
    if (parameterLive.assessment.id !== liveData.id) {
      const fields = {
        id: parameterLive.id,
        id_inspection_assessment: liveData.id,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    const parameterDisk = component.findInspectionParameterByOrder(3)
    if (parameterDisk.assessment.id !== diskData.id) {
      const fields = {
        id: parameterDisk.id,
        id_inspection_assessment: diskData.id,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: { id: component.id, cost },
    })
  }

  get color () {
    const { formData: { level: { answer } }, items } = this
    const item = items?.[answer]

    const progress = parseInt(item?.name.split('%')[0], 10)
    return getProgressColor(progress)
  }

  get colorLive () {
    const { formData: { live: { answer } }, itemsLive } = this
    const item = itemsLive?.[answer]

    const progress = parseInt(item?.name.split('%')[0], 10)
    return getProgressColor(progress)
  }

  get colorDisk () {
    const { formData: { diskLive: { answer } }, itemsDisk } = this
    const item = itemsDisk?.[answer]

    const progress = parseInt(item?.name.split('%')[0], 10)
    return getProgressColor(progress)
  }

  @Watch('brakes', { immediate: true })
  onCategoryComponentChange (val) {
    if (!val) return

    this.items = sortArray(val?.findQuestion(1).inspectionAssessments, 'name', true, '%') || []
    this.itemsLive = sortArray(val?.findQuestion(2).inspectionAssessments, 'name', true, '%') || []
    this.itemsDisk = sortArray(val?.findQuestion(3).inspectionAssessments, 'name', true, '%') || []
  }
  }
