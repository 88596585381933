
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { ContentBase } from '@/components/forms/inspection/steppers/content/ContentBase'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { sortArray } from '@/utils/array/manipulation'
  import { isArray } from 'class-validator'
  import { Debounce } from '@/utils/decorators'
  import { getProgressColor } from '@/utils/general'
  import { Component as CategoryComponent } from '@/entities/vehicle'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { GCostField, GFiles, GFormSlot },
  methods: {
    sortArray,
  },
})
  export default class EngineContent extends ContentBase {
  @Prop({ type: Object }) engine: CategoryComponent
  @Prop({ type: Boolean, default: false }) displayRating!: boolean
  oilItems = []
  fieldsKeys = ['status', 'functionality', 'oilLevel', 'oil', 'oilFilter']
  loadingSend = false

  formData = {
    status: {
      answer: null,
      provisional: null,
    },
    functionality: {
      answer: null,
      provisional: null,
    },
    oilLevel: {
      answer: null,
    },
    oil: {
      answer: null,
      provisional: null,
    },
    oilFilter: {
      answer: null,
      provisional: null,
    },
    cost: undefined,
    photo: null,
  }

  errorIdentifierMessage = {
    status: {
      status: '',
      provisional: ''
      ,
    },
    functionality: {
      status: '',
      provisional: '',
    },
    oil: {
      status: '',
      provisional: '',
    },
    oilFilter: {
      status: '',
      provisional: '',
    },
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  getName (items, field) {
    return items?.[field]?.name
  }

  async send () {
    const { formData: data } = this
    if (this.validateFields(data)) {
      return
    }
    this.loadingSend = true
    const { idProcess, idEmployee, inspection, engine: component, photoProperties, oilItems, idProcessInspection } = this
    let resp

    const statusData = data?.status?.provisional ? [data.status.provisional] : data.status.answer
    const functionalityData = data?.functionality?.provisional ? [data.functionality.provisional] : data.functionality.answer
    const oilData = data?.oil?.provisional ? [data.oil.provisional] : data.oil.answer
    const oilFilterData = data?.oilFilter?.provisional ? [data.oilFilter.provisional] : data.oilFilter.answer
    const oilLevelData = oilItems[data.oilLevel.answer]

    const inspectionUpdated = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: inspection.id } },
      force: true,
    })

    const matchingInspectedComponent = inspectionUpdated.inspectedComponents.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      resp = matchingInspectedComponent
      await this.edit(resp, data.cost, { statusData, functionalityData, oilData, oilFilterData, oilLevelData })
    } else {
      const fields = {
        id_inspection: inspection.id,
        id_inspection_component: component.inspectionComponent.id,
        id_process: idProcessInspection,
        cost: data?.cost,
        comment: undefined,
        inspection_qualifications: {
          data: [
            ...statusData.map(status => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(1)?.id,
                id_inspection_assessment: status,
              }
            }),
            ...functionalityData.map(functionality => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(2)?.id,
                id_inspection_assessment: functionality,
              }
            }),
            {
              id_employee: idEmployee,
              id_inspection_parameter: component?.findQuestion(3)?.id,
              id_inspection_assessment: oilLevelData?.id,
            },
            ...oilData.map(oil => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(4)?.id,
                id_inspection_assessment: oil,
              }
            }),
            ...oilFilterData.map(oil => {
              return {
                id_employee: idEmployee,
                id_inspection_parameter: component?.findQuestion(5)?.id,
                id_inspection_assessment: oil,
              }
            }),
          ].filter(_ => _.id_inspection_assessment),
        },
      }

      resp = await this.pushData({ model: 'InspectionInspectedComponent', fields })
    }

    await this.addFiles(data.photo, photoProperties, idProcess, resp)

    this.$emit('input', { cost: this.formData.cost })
    this.$emit('next')
    this.$emit('update:inspection')
    this.loadingSend = false
  }

  @Watch('formData', { immediate: true, deep: true })
  @Debounce(100)
  onFormDataChange (val) {
    this.initiateErrorMessage()
    const { engine, fieldsKeys, validation, oilItems } = this
    if (validation) {
      this.validation = false
      return
    }
    this.cost = this.isAnyFieldValid(fieldsKeys, val, engine)

    if (val.oilLevel.answer !== null) {
      this.cost = this.cost || Boolean(oilItems[val.oilLevel.answer]?.score)
    }

    if (!this.cost && val.cost) {
      this.formData.cost = 0
    }
  }

  initiateErrorMessage () {
    this.errorIdentifierMessage.status.status = ''
    this.errorIdentifierMessage.status.provisional = ''
    this.errorIdentifierMessage.functionality.status = ''
    this.errorIdentifierMessage.functionality.provisional = ''
    this.errorIdentifierMessage.oil.status = ''
    this.errorIdentifierMessage.oil.provisional = ''
    this.errorIdentifierMessage.oilFilter.status = ''
    this.errorIdentifierMessage.oilFilter.provisional = ''
  }

  setErrorMessage (field) {
    if (this.formData[field]?.provisional === null || this.formData[field]?.provisional === undefined) {
      this.errorIdentifierMessage[field].provisional = 'Este campo es requerido'
    } else if (this.formData[field]?.provisional !== null || this.formData[field]?.provisional !== undefined) {
      this.errorIdentifierMessage[field].provisional = ''
    }

    this.errorIdentifierMessage[field].status = 'Este campo es requerido'
  }

  validateFields (data) {
    const fields = ['status', 'functionality', 'oil', 'oilFilter']
    let isValid = false

    const handleValidation = field => {
      const isArr = isArray(data[field]?.answer)
      const isNum = !isNaN(data[field]?.answer)

      if ((isNum && !data[field]?.answer) || (isArr && !data[field]?.answer.length)) {
        this.setErrorMessage(field)
        return true
      }
      this.errorIdentifierMessage[field].status = ''
      return false
    }

    for (const field of fields) {
      const isProvisional = 'provisional' in data[field]
      const validNotProvisional = isProvisional === false && handleValidation(field)
      const validProvisional = (isProvisional === true && !data[field]?.provisional) && handleValidation(field)

      if (data[field]?.provisional) {
        this.validation = true

        if (this.formData?.[field]?.answer) {
          this.formData[field].answer = []
        }
      }

      if (validNotProvisional || validProvisional) {
        isValid = true
        break
      } else {
        this.errorIdentifierMessage[field].status = ''
      }
    }

    return isValid
  }

  @Watch('engine', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { idProcess, inspection, displayQualification } = this

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === val?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      this.formData.photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: matchingInspectedComponent.id } },
            { parameter: { process: { id: { _eq: idProcess } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })
      this.processFormData('status', 1, matchingInspectedComponent)
      this.processFormData('functionality', 2, matchingInspectedComponent)
      this.processFormData('oil', 4, matchingInspectedComponent)
      this.processFormData('oilFilter', 5, matchingInspectedComponent)

      this.formData.oilLevel.answer = this.oilItems?.findIndex(item => item.id === matchingInspectedComponent.findInspectionQualificationByOrder(3)?.[0]?.assessment?.id)
      this.formData.cost = matchingInspectedComponent.cost

      if (matchingInspectedComponent.cost) {
        this.cost = true
      }
    }
    this.$emit('input', { cost: this.formData.cost })
    const validation = ((this.formData.status.provisional === false && this.formData.status.answer) || (this.formData.status.provisional)) && !displayQualification
    if (validation && !this.displayRating) {
      this.$emit('next')
    }
  }

  processFormData (field, order, component) {
    const param = component.findInspectionParameterByOrder(order)
    const qual = component.findInspectionQualificationByOrder(order)

    if (!param?.assessment?.score) {
      this.formData[field].provisional = param?.assessment?.id
      this.formData[field].answer = null
    } else {
      this.formData[field].provisional = false
      this.formData[field].answer = qual.map(item => item.assessment.id)
    }
  }

  async edit (component, cost, data) {
    const { statusData, functionalityData, oilData, oilFilterData, oilLevelData } = data
    const { idEmployee } = this
    await this.editMultipleComponent(component, statusData, idEmployee, 1)
    await this.editMultipleComponent(component, functionalityData, idEmployee, 2)

    if (component.findInspectionParameterByOrder(3).assessment.id !== oilLevelData.id) {
      const fields = {
        id: component.findInspectionParameterByOrder(3).id,
        id_inspection_assessment: oilLevelData.id,
      }
      await this.pushData({ model: 'InspectionQualification', fields })
    }

    await this.editMultipleComponent(component, oilData, idEmployee, 4)
    await this.editMultipleComponent(component, oilFilterData, idEmployee, 5)

    await this.pushData({
      model: 'InspectionInspectedComponent',
      fields: { id: component.id, cost },
    })
  }

  get color () {
    const { formData: { oilLevel: { answer } }, oilItems } = this
    const item = oilItems?.[answer]

    const progress = parseInt(item?.name.split('%')[0], 10)
    return getProgressColor(progress)
  }

  @Watch('engine', { immediate: true })
  onCategoryComponentChange (val) {
    if (!val) return

    this.oilItems = sortArray(val?.findQuestion(3).inspectionAssessments, 'name', true, '%') || []
  }
  }
