
  import { Component, Watch } from 'vue-property-decorator'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { getProgressColor, isValidNumber, parseToNumber, replaceText } from '@/utils/general'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import { Debounce } from '@/utils/decorators'
  import { DealAutoAttribute } from '@/entities/public'
  import { plainToInstance } from 'class-transformer'
  import { sortArray } from '@/utils/array/manipulation'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  methods: { replaceText, sortArray },
  components: { GCostField, GFormSlot, GFiles, BaseStepper },
})
  export default class IdentifierStepper extends BaseStepper {
  componentKeys = ['registrationPlate', 'mileage', 'fuelLevel', 'chassis', 'engine', 'color']
  mileageAttribute: DealAutoAttribute = plainToInstance(DealAutoAttribute, {})

  mileageCustomMessage = ''
  mileageOver = false
  loadingForm = true
  loadingSend = false
  formData = {
    registrationPlate: {
      answer: null,
      photo: null,
    },
    mileage: {
      answer: null,
      photo: null,
      supervisorCost: undefined,
    },
    chassis: {
      answer: null,
      photo: null,
    },
    engine: {
      answer: null,
      photo: null,
    },
    color: {
      answer: null,
      photo: null,
    },
    fuelLevel: {
      answer: null,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    registrationPlate: '',
    chassis: '',
    engine: '',
    color: '',
    fuelLevel: '',
  }

  get registrationPlate () {
    return this.findComponentBySlug(this.inspectionComponents, 'registration_plate')
  }

  get mileage () {
    return this.findComponentBySlug(this.inspectionComponents, 'mileage')
  }

  get chassis () {
    return this.findComponentBySlug(this.inspectionComponents, 'chassis_serial')
  }

  get engine () {
    return this.findComponentBySlug(this.inspectionComponents, 'engine_serial')
  }

  get color () {
    return this.findComponentBySlug(this.inspectionComponents, 'color')
  }

  get fuelLevel () {
    return this.findComponentBySlug(this.inspectionComponents, 'fuel_level')
  }

  get allComponents () {
    const { registrationPlate, mileage, chassis, engine, color, fuelLevel } = this

    return {
      registrationPlate,
      mileage,
      chassis,
      engine,
      color,
      fuelLevel,
    }
  }

  getColor (value) {
    const { fuelLevel } = this
    const item = sortArray(fuelLevel.inspectionComponent.componentOptions, 'name', true, '%')[value]
    if (!item) return null

    const percentage = parseInt(item.name.split('%')[0], 10)

    return getProgressColor(percentage)
  }

  async send (component, data, field = null, fuel = false) {
    if (field) {
      if (!data?.answer) {
        this.errorIdentifierMessage[field] = 'Este campo es requerido'
      } else {
        this.errorIdentifierMessage[field] = ''
      }
    }

    if (!data.answer || !data?.photo.length) return
    this.loadingSend = true
    const {
      step,
      photoProperties,
      inspection,
      mileageAttribute,
      supervisor,
      categoryName,
      category,
    } = this

    if (fuel) {
      const answer = sortArray(component.inspectionComponent.componentOptions, 'name', true, '%')[data.answer]
      await this.sendComponentQualification(component, {
        answer: answer.id,
        photo: data.photo,
      }, photoProperties, inspection.id, mileageAttribute, supervisor)
      this.step = step + 1
      this.loadingSend = false
      return
    }

    await this.sendComponentQualification(component, data, photoProperties, inspection.id, mileageAttribute, supervisor)
    this.step = step + 1
    this.$emit('update:inspection')

    await this.setSupervisorId()

    if (this.step > this.componentKeys.length) {
      const total = this.getTotalCost(this.formData, 'supervisorCost')
      this.$emit('input', { categoryName, category, total })
      this.$emit('inspection-category-done')
    }

    this.loadingSend = false
  }

  async setSupervisorId () {
    const { inspection, supervisor, idEmployee } = this

    if (!supervisor) return

    if (!inspection?.supervisor?.id) {
      await this.pushData({
        model: 'Inspection',
        fields: {
          id: inspection.id,
          id_supervisor: idEmployee,
        },
      })
    }

    this.$emit('update:inspection')
  }

  @Watch('step', { immediate: true })
  @Debounce(100)
  async onStepChange () {
    const {
      inspection,
      categoryName,
      displayQualification,
      progress,
      selfPanel,
    } = this

    if (!displayQualification && selfPanel) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 1)
    }
  }

  get progress () {
    const { step } = this

    if (step > 1) {
      return ((step - 1) / 6) * 100
    }

    return 0
  }

  get dealAutoAttributesData () {
    const { inspection, mileage, idProcessInspection } = this

    return { inspection, component: mileage, idProcess: idProcessInspection }
  }

  @Watch('dealAutoAttributesData', { immediate: true, deep: true })
  @Debounce(100)
  async onDealAutoAttributeDataChange (val) {
    if (!val?.component?.id) return
    const dealAutoAttribute = await this.getDealAutoAttribute(val.component.id, val.idProcess, val.inspection.id)

    this.mileageAttribute = dealAutoAttribute?.[0]
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const {
      idProcess,
      inspection,
      componentKeys,
    } = this
    this.step = 1
    const handleComponentChange = async (component, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        const value = matchingInspectedComponent.componentQualificationValue
        const idAnswer = matchingInspectedComponent.componentQualification?.[0]?.id

        if (key === 'fuelLevel') {
          this.formData[key].answer = sortArray(component.inspectionComponent.componentOptions, 'name', true, '%').findIndex(option => {
            const val = value?.[0] || idAnswer

            return option.id === val
          })
        } else {
          this.formData[key].answer = value?.[0] || idAnswer
          if ('supervisorCost' in this.formData[key]) {
            this.formData[key].supervisorCost = matchingInspectedComponent.supervisorCost
          }
        }
      }
    }

    for (const key of componentKeys) {
      await handleComponentChange(val[key], key)
    }

    this.loadingForm = false
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get updateMileage () {
    const { inspection, formData: { mileage }, maxMileageDifference, minPercentageDifference } = this

    return {
      inspection,
      mileage: mileage.answer,
      maxMileageDifference,
      minPercentageDifference,
    }
  }

  @Watch('updateMileage', { immediate: true, deep: true })
  onMileageChange (val) {
    if (!isValidNumber(val.mileage)) return
    this.mileageOver = false
    this.mileageCustomMessage = ''
    const { maxMileageDifference, minPercentageDifference } = val

    const mileage = parseToNumber(val.mileage) - parseToNumber(val.inspection.dealMileage)

    if (mileage > maxMileageDifference) {
      this.mileageOver = true
      this.mileageCustomMessage = `Diferencia de kilometraje máximo "${maxMileageDifference}" sobrepasado`
    } else {
      const lessThanPercent = (val.inspection.dealMileage * minPercentageDifference)
      if (parseToNumber(val.mileage) < lessThanPercent) {
        this.mileageOver = true
        this.mileageCustomMessage = `Diferencia de kilometraje mínimo "${lessThanPercent}" sobrepasado`
      } else {
        this.mileageOver = false
        this.mileageCustomMessage = ''
      }
    }
  }

  get viewCategory () {
    const { selfPanel, overCategory } = this

    return {
      selfPanel,
      overCategory,
    }
  }

  @Watch('viewCategory', { immediate: true, deep: true })
  onViewCategoryChange (val) {
    if (val.selfPanel && val.overCategory) {
      this.step = 1
    }
  }

  @Watch('mileageOver', { immediate: true })
  onMileageStatusChange (val) {
    if (!val && !this.loadingForm) {
      this.formData.mileage.supervisorCost = 0
    }
  }
  }
