
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { ContentBase } from '@/components/forms/inspection/steppers/content/ContentBase'
  import { sortArray } from '@/utils/array/manipulation'
  import { Debounce } from '@/utils/decorators'
  import { Component as CategoryComponent } from '@/entities/vehicle'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  components: { RowData },
  methods: {
    sortArray,
  },
})
  export default class RefrigerantSupervisorContent extends ContentBase {
  @Prop({ type: Object }) refrigerant: CategoryComponent
  fieldsKeys = ['status', 'level', 'filter']

  formData = {
    status: {
      answer: null,
      provisional: null,
    },
    level: {
      answer: null,
    },
    filter: {
      answer: null,
      provisional: null,
    },
    cost: undefined,
    supervisorCost: undefined,
    photo: null,
  }

  errorIdentifierMessage = ''

  get items () {
    const { refrigerant } = this

    return sortArray(refrigerant?.findQuestion(1).inspectionAssessments, 'name', true, '%') || []
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  getName (items, field) {
    return items?.[field]?.name
  }

  async send () {
    const { formData, refrigerant, inspection } = this

    if (formData.cost && (formData.supervisorCost === null || formData.supervisorCost === undefined)) {
      this.errorIdentifierMessage = 'El costo es requerido'
      return false
    } else {
      this.errorIdentifierMessage = ''
    }

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === refrigerant?.inspectionComponent?.id
    )
    if (matchingInspectedComponent) {
      await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)

      if (formData?.supervisorCost) {
        await this.pushData({
          model: 'InspectionInspectedComponent',
          fields: { id: matchingInspectedComponent.id, supervisor_cost: formData.supervisorCost || 0 },
        })
      }
    }

    return parseInt(formData.supervisorCost) || 0
  }

  get componentChange () {
    const { refrigerant, selfPanel } = this

    return {
      refrigerant,
      selfPanel,
    }
  }

  @Watch('componentChange', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (componentChange) {
    const { refrigerant: val } = componentChange

    const { idProcess, inspection } = this
    if (!val || !inspection || !idProcess) return null

    const matchingInspectedComponent = inspection.inspectedComponents.find(
      ic => ic.inspectionComponent.id === val?.inspectionComponent?.id
    )

    if (matchingInspectedComponent) {
      this.formData.photo = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: matchingInspectedComponent.id } },
            { parameter: { process: { id: { _eq: idProcess } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })

      this.processFormData('status', 2, matchingInspectedComponent)
      this.processFormData('filter', 3, matchingInspectedComponent)

      this.formData.level.answer = this.items?.findIndex(item => item.id === matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.assessment?.id)
      this.formData.cost = matchingInspectedComponent.cost
      this.formData.supervisorCost = isValidNumber(matchingInspectedComponent.supervisorCost) ? matchingInspectedComponent.supervisorCost : matchingInspectedComponent.cost
      this.$emit('input', { total: this.formData.supervisorCost })
      if (matchingInspectedComponent.cost) {
        this.cost = true
      }
    }
  }

  processFormData (field, order, component) {
    const param = component.findInspectionParameterByOrder(order)
    const qual = component.findInspectionQualificationByOrder(order)

    if (!param?.assessment?.score) {
      this.formData[field].provisional = param?.assessment?.id
      this.formData[field].answer = null
    } else {
      this.formData[field].provisional = false
      this.formData[field].answer = qual.map(item => item.assessment.id)
    }
  }
  }
