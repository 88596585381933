
  import { Component, Watch } from 'vue-property-decorator'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import { Debounce } from '@/utils/decorators'
  import GFiles from '@/components/core/files/GFiles.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import { isValidNumber } from '@/utils/general'

@Component({
  methods: { isValidNumber },
  components: { RowData, RatingCell, GFiles, BasePanel, GFormSlot },
})
  export default class RearviewMirrorSupervisorPanel extends BaseStepper {
  componentKeys = ['windshield', 'leftFrontWindow', 'leftRearWindow', 'rearWindow', 'rightRearWindow', 'rightFrontWindow']
  validation = false
  fieldsKeys = ['original', 'status']
  total = null

  formData = {
    windshield: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    leftFrontWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    leftRearWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rearWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rightRearWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
    rightFrontWindow: {
      original: {
        answer: null,
      },
      status: {
        answer: null,
        provisional: null,
      },
      cost: undefined,
      supervisorCost: undefined,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    windshield: '',
    leftFrontWindow: '',
    leftRearWindow: '',
    rearWindow: '',
    rightRearWindow: '',
    rightFrontWindow: '',
  }

  async mounted () {
    const { category } = this

    this.components = category.components
  }

  get statusItems () {
    const {
      windshield,
      leftFrontWindow,
      leftRearWindow,
      rearWindow,
      rightRearWindow,
      rightFrontWindow,
    } = this

    return {
      windshield: windshield.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      leftFrontWindow: leftFrontWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      leftRearWindow: leftRearWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rearWindow: rearWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rightRearWindow: rightRearWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
      rightFrontWindow: rightFrontWindow.findQuestion(2).inspectionAssessments.filter(item => item.score !== 0),
    }
  }

  get statusAnswer () {
    const {
      windshield,
      leftFrontWindow,
      leftRearWindow,
      rearWindow,
      rightRearWindow,
      rightFrontWindow,

    } = this
    return {
      windshield: windshield.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      leftFrontWindow: leftFrontWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      leftRearWindow: leftRearWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rearWindow: rearWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rightRearWindow: rightRearWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
      rightFrontWindow: rightFrontWindow.findQuestion(2).inspectionAssessments.find(item => item.score === 0),
    }
  }

  get rightFrontWindow () {
    return this.findComponentBySlug(this.components, 'right_front_window')
  }

  get rightRearWindow () {
    return this.findComponentBySlug(this.components, 'right_rear_window')
  }

  get rearWindow () {
    return this.findComponentBySlug(this.components, 'rear_window')
  }

  get leftRearWindow () {
    return this.findComponentBySlug(this.components, 'left_rear_window')
  }

  get windshield () {
    return this.findComponentBySlug(this.components, 'windshield')
  }

  get leftFrontWindow () {
    return this.findComponentBySlug(this.components, 'left_front_window')
  }

  async send () {
    const { allComponents, inspection, categoryName, category, formData } = this

    let error = false
    const keys = Object.keys(allComponents)

    for (const key of keys) {
      if (formData?.[key]?.cost && (formData[key].supervisorCost === null || formData[key].supervisorCost === undefined)) {
        this.errorIdentifierMessage[key] = 'El costo es requerido'
        error = true
      } else {
        this.errorIdentifierMessage[key] = ''
      }
    }

    if (error) return
    await Promise.all(keys.map(async key => {
      const component = allComponents[key]
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        await this.updateInspectionQualificationValidated(matchingInspectedComponent.findInspectionQualificationByOrder(1)?.[0]?.id)

        if (formData?.[key]?.supervisorCost) {
          await this.pushData({
            model: 'InspectionInspectedComponent',
            fields: { id: matchingInspectedComponent.id, supervisor_cost: formData[key].supervisorCost || 0 },
          })
        }
      }
    }))
    this.total = this.getTotalCost(this.formData, 'supervisorCost')
    this.$emit('input', { categoryName, category, total: this.total })
    this.$emit('inspection-category-done')
  }

  get allComponents () {
    const {
      windshield,
      leftFrontWindow,
      leftRearWindow,
      rearWindow,
      rightRearWindow,
      rightFrontWindow,
      selfPanel,
    } = this

    return {
      windshield,
      leftFrontWindow,
      leftRearWindow,
      rearWindow,
      rightRearWindow,
      rightFrontWindow,
      selfPanel,
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification } = this
    this.step = 1
    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        this.formData[key].original.answer = matchingInspectedComponent.findInspectionParameterByOrder(1).assessment.id

        if (!matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.score) {
          this.formData[key].status.provisional = matchingInspectedComponent.findInspectionParameterByOrder(2)?.assessment?.id
          this.formData[key].status.answer = null
        } else {
          this.formData[key].status.provisional = false
          this.formData[key].status.answer = matchingInspectedComponent.findInspectionQualificationByOrder(2).map(item => item.assessment.id)
        }
        this.formData[key].cost = matchingInspectedComponent.cost
        this.formData[key].supervisorCost = isValidNumber(matchingInspectedComponent.supervisorCost) ? matchingInspectedComponent.supervisorCost : matchingInspectedComponent.cost
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val[key], index + 1, key)
    }
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('inspection', { immediate: true, deep: true })
  onInspectionDataChange (val) {
    if (!val?.id) return
    const { category } = this

    if (!isValidNumber(this.total)) {
      const totalSupervisor = val.supervisorMetadata?.findCategoryByName(category.name)?.total
      const totalInspector = val.metadata.findCategoryByName(category.name)?.total
      this.total = totalSupervisor || totalInspector
    }
  }
  }
