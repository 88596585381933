export function sortArray (array, field, transformToNumber = false, splitChar = null, order = 'asc') {
  if (!array || !field) return null

  return array.sort((a, b) => {
    let valA = a[field]
    let valB = b[field]

    if (transformToNumber) {
      if (splitChar) {
        valA = valA.split(splitChar)[0]
        valB = valB.split(splitChar)[0]
      }
      valA = Number(valA)
      valB = Number(valB)
    }

    if (typeof valA === 'number' && typeof valB === 'number') {
      return order === 'asc' ? valA - valB : valB - valA
    } else {
      const strA = String(valA).toLowerCase()
      const strB = String(valB).toLowerCase()
      return order === 'asc'
        ? strA.localeCompare(strB)
        : strB.localeCompare(strA)
    }
  })
}
