
  import { Component, Watch } from 'vue-property-decorator'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import IdentifierStepper from '@/components/forms/inspection/steppers/IdentifierStepper.vue'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { DealAutoAttribute } from '@/entities/public'
  import { plainToInstance } from 'class-transformer'
  import { sortArray } from '@/utils/array/manipulation'
  import { getProgressColor, parseToNumber, replaceText } from '@/utils/general'
  import { Debounce } from '@/utils/decorators'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'

@Component({
  methods: { replaceText, sortArray },
  components: { RowData, GFiles, GFormSlot, RatingCell, IdentifierStepper, BasePanel },
})
  export default class IdentifierSupervisorPanel extends BaseStepper {
  componentKeys = ['registrationPlate', 'mileage', 'fuelLevel', 'chassis', 'engine', 'color']
  mileageAttribute: DealAutoAttribute = plainToInstance(DealAutoAttribute, {})

  mileageCustomMessage = ''
  mileageOver = false
  total = null
  formData = {
    registrationPlate: {
      answer: null,
      photo: null,
    },
    mileage: {
      answer: null,
      photo: null,
      cost: undefined,
      supervisorCost: undefined,
    },
    chassis: {
      answer: null,
      photo: null,
    },
    engine: {
      answer: null,
      photo: null,
    },
    color: {
      answer: null,
      photo: null,
    },
    fuelLevel: {
      answer: null,
      photo: null,
    },
  }

  errorIdentifierMessage = {
    registrationPlate: '',
    chassis: '',
    mileage: '',
    engine: '',
    color: '',
    fuelLevel: '',
  }

  get registrationPlate () {
    return this.findComponentBySlug(this.inspectionComponents, 'registration_plate')
  }

  get mileage () {
    return this.findComponentBySlug(this.inspectionComponents, 'mileage')
  }

  get chassis () {
    return this.findComponentBySlug(this.inspectionComponents, 'chassis_serial')
  }

  get engine () {
    return this.findComponentBySlug(this.inspectionComponents, 'engine_serial')
  }

  get color () {
    return this.findComponentBySlug(this.inspectionComponents, 'color')
  }

  get fuelLevel () {
    return this.findComponentBySlug(this.inspectionComponents, 'fuel_level')
  }

  get allComponents () {
    const { registrationPlate, mileage, chassis, engine, color, fuelLevel } = this

    return {
      registrationPlate,
      mileage,
      chassis,
      engine,
      color,
      fuelLevel,
    }
  }

  getColor (value) {
    const { fuelLevel } = this
    const item = sortArray(fuelLevel.inspectionComponent.componentOptions, 'name', true, '%')[value]
    if (!item) return null

    const percentage = parseInt(item.name.split('%')[0], 10)

    return getProgressColor(percentage)
  }

  async send () {
    const { allComponents, inspection, categoryName, category, formData } = this

    const keys = Object.keys(allComponents)
    await Promise.all(keys.map(async key => {
      const component = allComponents[key]
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        const idQualification = matchingInspectedComponent.inspectionQualification.id
        await this.updateInspectionQualificationValidated(idQualification)

        if (formData?.[key]?.supervisorCost) {
          await this.pushData({
            model: 'InspectionInspectedComponent',
            fields: { id: matchingInspectedComponent.id, supervisor_cost: formData[key].supervisorCost || 0 },
          })
        }
      }
    }))
    this.total = formData?.mileage?.cost || 0
    this.$emit('input', { categoryName, category, total: formData.mileage.cost || 0 })
    this.$emit('inspection-category-done')
  }

  get dealAutoAttributesData () {
    const { inspection, mileage, idProcessInspection } = this

    return { inspection, component: mileage, idProcess: idProcessInspection }
  }

  @Watch('dealAutoAttributesData', { immediate: true, deep: true })
  @Debounce(100)
  async onDealAutoAttributeDataChange (val) {
    if (!val?.component?.id) return
    const dealAutoAttribute = await this.getDealAutoAttribute(val.component.id, val.idProcess, val.inspection.id)

    this.mileageAttribute = dealAutoAttribute?.[0]
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(100)
  async onComponentsChange (val) {
    const { idProcess, inspection, componentKeys, autoClose, disabled, displayQualification } = this
    this.step = 1
    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        const value = matchingInspectedComponent.componentQualificationValue
        const idAnswer = matchingInspectedComponent.componentQualification?.[0]?.id

        if (key === 'fuelLevel') {
          this.formData[key].answer = sortArray(component.inspectionComponent.componentOptions, 'name', true, '%').findIndex(option => {
            const val = value?.[0] || idAnswer

            return option.id === val
          })
        } else {
          this.formData[key].answer = value?.[0] || idAnswer
          if ('cost' in this.formData[key]) {
            this.formData[key].cost = matchingInspectedComponent.cost
            if ('supervisorCost' in this.formData[key]) {
              this.formData[key].supervisorCost = matchingInspectedComponent.supervisorCost || matchingInspectedComponent.cost
            }
          }
        }
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val[key], index + 2, key)
    }
  }

  get updateMileage () {
    const { inspection, formData: { mileage } } = this

    return {
      inspection,
      mileage: mileage.answer,
    }
  }

  @Watch('updateMileage', { immediate: true, deep: true })
  onMileageChange (val) {
    this.mileageOver = false
    this.mileageCustomMessage = ''
    const { maxMileageDifference } = this
    const mileage = parseToNumber(val.mileage) - parseToNumber(val.inspection.dealMileage)
    if (mileage > maxMileageDifference) {
      this.mileageOver = true
      this.mileageCustomMessage = `Diferencia de kilometraje máximo "${maxMileageDifference}" sobrepasado`
    }
  }

  @Watch('mileageOver', { immediate: true })
  onMileageStatusChange (val) {
    if (!val) {
      this.formData.mileage.cost = 0
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.supervisorMetadata) || Boolean(inspection.metadata)
  }

  @Watch('inspection', { immediate: true, deep: true })
  onFormDataChange (val) {
    if (!val?.id) return
    const { category } = this
    const totalSupervisor = val.supervisorMetadata?.findCategoryByName(category.name)?.total
    const totalInspector = val.metadata.findCategoryByName(category.name)?.total
    this.total = totalSupervisor || totalInspector
  }
  }
